.web-development {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.web-development h2 {
  font-size: 3rem;
}

.web-development .bottom-section {
  display: none;
}
